import React, { type FunctionComponent } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  color?: string;
}

const ObservedIcon: FunctionComponent<Props> = ({ className, color = '#171717' }) => (
  <svg
    width="53"
    height="14"
    viewBox="0 0 53 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(className, 'sl-observed-icon')}
    data-testid="observation-observed-badge"
  >
    <rect x="0.5" y="0.5" width="51.3646" height="13" rx="1.5" stroke={color} />
    <path
      d="M45.4871 4.13037C47.5006 4.13037 48.3648 5.48363 48.3648 6.99994C48.3648 8.51624 47.4517 9.8695 45.4871 9.8695H43.2778V4.13037H45.4871ZM45.5115 8.69559C46.5061 8.69559 46.9952 7.86407 46.9952 6.99994C46.9952 6.13581 46.5061 5.30428 45.5115 5.30428H44.6474V8.69559H45.5115Z"
      fill={color}
    />
    <path
      d="M42.2251 5.30428H39.5512V6.44559H41.7034V7.55428H39.5512V8.69559H42.2251V9.8695H38.1816V4.13037H42.2251V5.30428Z"
      fill={color}
    />
    <path
      d="M36.1794 4.13037H37.6468L35.4946 9.8695H34.1903L32.0381 4.13037H33.5055L34.8424 8.14124L36.1794 4.13037Z"
      fill={color}
    />
    <path
      d="M32.0224 9.8695H30.4572L29.2507 7.91298H28.4355V9.8695H27.0659V4.13037H29.5279C31.0768 4.13037 31.7616 4.9782 31.7616 5.98907C31.7616 6.72276 31.3703 7.39939 30.6121 7.72548L32.0224 9.8695ZM28.4355 5.30428V6.80428H29.4545C30.017 6.80428 30.392 6.51081 30.392 6.05428C30.392 5.5407 30.017 5.30428 29.4545 5.30428H28.4355Z"
      fill={color}
    />
    <path
      d="M26.0132 5.30428H23.3393V6.44559H25.4915V7.55428H23.3393V8.69559H26.0132V9.8695H21.9697V4.13037H26.0132V5.30428Z"
      fill={color}
    />
    <path
      d="M18.7626 6.22554C20.1078 6.4212 21.0452 6.82065 21.0452 8.125C21.0452 9.13587 20.23 10 18.6974 10C17.1322 10 16.3496 8.97283 16.3496 7.84783H17.7029C17.7029 8.35326 17.9637 8.8913 18.6974 8.8913C19.3904 8.8913 19.6757 8.52446 19.6757 8.20652C19.6757 7.86413 19.423 7.66848 18.6974 7.5462C17.067 7.28533 16.5453 6.72283 16.5453 5.71196C16.5453 4.75 17.3523 4 18.7137 4C20.1404 4 20.7925 4.9212 20.8333 5.8913H19.5126C19.5126 5.5163 19.2762 5.1087 18.6974 5.1087C18.1186 5.1087 17.9067 5.41848 17.9067 5.66304C17.9067 5.88315 18.0126 6.11141 18.7626 6.22554Z"
      fill={color}
    />
    <path
      d="M14.5336 6.86135C15.1858 7.06515 15.5526 7.61135 15.5526 8.22276C15.5526 9.16842 14.9168 9.8695 13.7429 9.8695H10.7266V4.13037H13.27C14.5255 4.13037 15.2918 4.66842 15.2918 5.69559C15.2918 6.22548 14.9331 6.682 14.5336 6.86135ZM12.0961 5.27168V6.38037H13.1885C13.6858 6.38037 13.9222 6.14396 13.9222 5.79342C13.9222 5.43472 13.6858 5.27168 13.1885 5.27168H12.0961ZM13.3353 8.7282C13.8896 8.7282 14.1831 8.50809 14.1831 8.10863C14.1831 7.73363 13.8896 7.48907 13.3353 7.48907H12.0961V8.7282H13.3353Z"
      fill={color}
    />
    <path
      d="M6.93477 4C8.79347 4 9.86955 5.34511 9.86955 7C9.86955 8.65489 8.79347 10 6.93477 10C5.07608 10 4 8.65489 4 7C4 5.34511 5.07608 4 6.93477 4ZM6.93477 8.82609C7.87227 8.82609 8.49999 8.01087 8.49999 7C8.49999 5.98913 7.87227 5.17391 6.93477 5.17391C5.99728 5.17391 5.36956 5.98913 5.36956 7C5.36956 8.01087 5.99728 8.82609 6.93477 8.82609Z"
      fill={color}
    />
  </svg>
);

export default ObservedIcon;
